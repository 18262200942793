/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/2053df8159b25386-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/9d9b9cae20d87d18-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/891487401855818d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/64ea2a5aaa4dedd3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/b63e4df112f8dce1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/1a142ec2652f2d06-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/dba81c1208da12ee-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/2053df8159b25386-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/9d9b9cae20d87d18-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/891487401855818d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/64ea2a5aaa4dedd3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/b63e4df112f8dce1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/1a142ec2652f2d06-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/dba81c1208da12ee-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/2053df8159b25386-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/9d9b9cae20d87d18-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/891487401855818d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/64ea2a5aaa4dedd3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/b63e4df112f8dce1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/1a142ec2652f2d06-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_612306';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/dba81c1208da12ee-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Code_Pro_Fallback_612306';src: local("Arial");ascent-override: 73.11%;descent-override: 20.28%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_612306 {font-family: '__Source_Code_Pro_612306', '__Source_Code_Pro_Fallback_612306';font-style: normal
}.__variable_612306 {--source-code-pro: '__Source_Code_Pro_612306', '__Source_Code_Pro_Fallback_612306'
}

/*
* Vars
*/

.Default_svgIcon___F9Lm {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  color: inherit;
}

.Default_primary__QG1iK {
  color: var(--icon-primary-color);
}

.Default_secondary__Z8__I {
  color: var(--icon-secondary-color);
}

.Default_tertiary__hSiBQ {
  color: var(--icon-tertiary-color);
}

.Default_transparent__3fxEh {
  color: transparent;
}

:root {
  /* heycar mint color */
  --color-heycar-mint-50: #e6faf6;
  --color-heycar-mint-100: #ccf5ed;
  --color-heycar-mint-200: #99ebdb;
  --color-heycar-mint-300: #66e2c9;
  --color-heycar-mint-400: #33d8b7;
  --color-heycar-mint-500: #00cea5;
  --color-heycar-mint-600: #00a584;
  --color-heycar-mint-700: #007c63;
  --color-heycar-mint-800: #005242;
  --color-heycar-mint-900: #002921;

  /* mica blue color */
  --color-mica-blue-50: #f1f4fa;
  --color-mica-blue-100: #dee6f2;
  --color-mica-blue-200: #a2b7da;
  --color-mica-blue-300: #7394c8;
  --color-mica-blue-400: #4570b5;
  --color-mica-blue-500: #164ca3;
  --color-mica-blue-600: #123d82;
  --color-mica-blue-700: #052962;
  --color-mica-blue-800: #091e41;
  --color-mica-blue-900: #040f21;

  /* sunbeam blue color */
  --color-sunbeam-blue-50: #f0f9ff;
  --color-sunbeam-blue-100: #e8f6ff;
  --color-sunbeam-blue-200: #e1f3ff;
  --color-sunbeam-blue-300: #d9f0ff;
  --color-sunbeam-blue-400: #cdebff;
  --color-sunbeam-blue-500: #c0e6ff;
  --color-sunbeam-blue-600: #9bd1f4;
  --color-sunbeam-blue-700: #73b6e1;
  --color-sunbeam-blue-800: #4499cf;
  --color-sunbeam-blue-900: #2b6f99;

  /* mustang yellow color */
  --color-mustang-yellow-50: #fef8eb;
  --color-mustang-yellow-100: #fef0d8;
  --color-mustang-yellow-200: #fde1b0;
  --color-mustang-yellow-300: #fbd289;
  --color-mustang-yellow-400: #fac361;
  --color-mustang-yellow-500: #f9b43a;
  --color-mustang-yellow-600: #e29a1a;
  --color-mustang-yellow-700: #c9850c;
  --color-mustang-yellow-800: #ae7000;
  --color-mustang-yellow-900: #895800;

  /* old ferrari red color */
  --color-old-ferrari-red-50: #fff1ef;
  --color-old-ferrari-red-100: #ffe3df;
  --color-old-ferrari-red-200: #ffc8be;
  --color-old-ferrari-red-300: #ffac9e;
  --color-old-ferrari-red-400: #ff917d;
  --color-old-ferrari-red-500: #ff755d;
  --color-old-ferrari-red-600: #f46147;
  --color-old-ferrari-red-700: #e24529;
  --color-old-ferrari-red-800: #d73013;
  --color-old-ferrari-red-900: #c7270a;

  /* -- fantasy name of "green" color to be defined -- */
  --color-green-50: #e9f3ed;
  --color-green-100: #d2e8db;
  --color-green-200: #a5d1b8;
  --color-green-300: #79b994;
  --color-green-400: #4ca271;
  --color-green-500: #1f8b4d;
  --color-green-600: #196f3e;
  --color-green-700: #13532e;
  --color-green-800: #0c381f;
  --color-green-900: #061c0f;

  /* -- fantasy name of "whatsapp" color to be defined -- */
  --color-whatsapp-500: #59ce72;
  --color-whatsapp-600: #33a84c;
  --color-whatsapp-700: #148a2d;

  /* -- fantasy name of "red" color to be defined -- */
  --color-red-50: #fdeeee;
  --color-red-100: #fbdddd;
  --color-red-200: #f7bcbc;
  --color-red-300: #f39a9a;
  --color-red-400: #ef7979;
  --color-red-500: #eb5757;
  --color-red-600: #d73535;
  --color-red-700: #c61a1a;
  --color-red-800: #b90606;
  --color-red-900: #a00;

  /* tarmac grey color */
  --color-tarmac-grey-50: #f3f3f3;
  --color-tarmac-grey-100: #e6e6e6;
  --color-tarmac-grey-200: #cdcdcd;
  --color-tarmac-grey-300: #b5b5b5;
  --color-tarmac-grey-400: #9c9c9c;
  --color-tarmac-grey-500: #838383;
  --color-tarmac-grey-600: #595959;
  --color-tarmac-grey-700: #303030;
  --color-tarmac-grey-800: #262626;
  --color-tarmac-grey-900: #1d1d1d;

  /* brand colors */
  --color-heycar-mint: var(--color-heycar-mint-500);
  --color-mica-blue: var(--color-mica-blue-700);
  --color-sunbeam-blue: var(--color-sunbeam-blue-500);
  --color-championship-white: #fff;
  --color-overlay: rgba(4, 15, 33, 0.6);

  /* secondary colors */
  --warning-100: #fef3df;
  --warning-200: #fde7c0;
  --warning-400: #fbcf81;
}
:root {
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;
  --spacing-14: 56px;
  --spacing-16: 64px;
  --spacing-17: 68px;
  --spacing-18: 72px;
  --spacing-20: 80px;
}
:root {
  --shadow-none: 'none';
  --shadow-xs: 0 2px 4px rgba(38, 38, 38, 0.06);
  --shadow-s: 0 2px 8px rgba(38, 38, 38, 0.08);
  --shadow-m: 0 4px 12px rgba(38, 38, 38, 0.08);
  --shadow-xl: 0 6px 24px rgba(38, 38, 38, 0.1);
  --shadow-xxl: 2px 8px 48px rgba(38, 38, 38, 0.2);
}
:root {
  /* Font families */
  --font-family-system: Objektiv, sans-serif, 'Helvetica Neue', Helvetica, Arial;

  /* Font weights */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;
  --font-weight-xbold: 800;
}
/*
  Heading
*/
.typography-display {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-family: var(--font-family-system);
  font-weight: var(--font-weight-xbold);
}
@media (min-width: 768px) {
.typography-display {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -2.5px;
}
  }
.typography-display-2 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-family: var(--font-family-system);
  font-weight: var(--font-weight-bold);
}
@media (min-width: 768px) {
.typography-display-2 {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -2.5px;
}
  }
.typography-heading-1 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-1_mobile {
  letter-spacing: -0.5px;
  font-size: 32px;
  line-height: 40px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-2 {
  font-size: 32px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-2_mobile {
  font-size: 28px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-3 {
  font-size: 28px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-3_mobile {
  font-size: 24px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-4 {
  font-size: 24px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-4_mobile {
  letter-spacing: 0;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-5 {
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-5_mobile {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-6 {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
/*
  Sub-Heading
*/
.typography-subheading-1 {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-subheading-2 {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-subheading-3 {
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-subheading-4 {
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
/*
  Body
*/
.typography-body-1 {
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-body-2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-body-3 {
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
.typography-body-4 {
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
/*
  Caption
*/
.typography-caption-1 {
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-caption-2 {
  font-size: 12px;
  line-height: 18px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
.typography-caption-3 {
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-caption-4 {
  font-size: 10px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-caption-5 {
  font-size: 10px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
/*
  Overline
*/
.typography-overline-1 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-overline-2 {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
/*
  Button
*/
.typography-button-1 {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}
.typography-button-2 {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}
.typography-button-3 {
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}
/* If this is ever changed please update breakpoints.json as well */
/* Mobile */
/* Tablet */
/* Desktop */
/* Sizes */
:root {
  --button-small-height: var(--size-small-height);
  --button-large-height: var(--size-large-height);
}
/* Variants */
:root {
  /* disabled */
  --button-disabled-color: var(--color-neutral-300);
  --button-disabled-bg-color: var(--color-neutral-50);

  /* primary */
  --button-primary-color: #fff;
  --button-primary-base-bg-color: var(--color-primary-500);
  --button-primary-hover-bg-color: var(--color-primary-600);
  --button-primary-active-bg-color: var(--color-primary-700);

  /* tertiary */
  --button-tertiary-color: var(--color-neutral-700);
  --button-tertiary-base-bg-color: var(--color-tertiary-500);
  --button-tertiary-hover-bg-color: var(--color-tertiary-600);
  --button-tertiary-active-bg-color: var(--color-tertiary-700);

  /* whatsapp */
  --button-whatsapp-color: #fff;
  --button-whatsapp-base-bg-color: var(--color-whatsapp-500);
  --button-whatsapp-hover-bg-color: var(--color-whatsapp-600);
  --button-whatsapp-active-bg-color: var(--color-whatsapp-700);
}
/* Chip */
:root {
  /* border */
  --chip-border-color: var(--color-neutral-300);

  /* default */
  --chip-default-background: var(--color-neutral-50);

  /* hover */
  --chip-hover-background: var(--color-neutral-100);

  /* pressed */
  --chip-pressed-background: var(--color-neutral-200);

  /* disable */
  --chip-disable-background: var(--color-neutral-50);

  /* text */
  --chip-text-color: var(--color-tarmac-grey-700);

  /* text disabled */
  --chip-text-disabled-color: var(--color-tarmac-grey-400);

  /* selected color */
  --chip-selected-color: var(--color-mica-blue-500);

  /* border */
  --chip-selected-border-color: var(--color-mica-blue-500);
}
/* Dropdown */
:root {
  --option-background-color: var(--color-tarmac-grey-50);
  --option-text-color: var(--color-tarmac-grey-700);
  --container-border-color: var(--color-neutral-500);
  --container-focus-border-color: var(--color-mica-blue-500);
  --container-disabled-color: var(--color-neutral-300);
  --options-border-color: var(--color-neutral-700);
  --options-background-color: var(--color-championship-white);
}
/* FormControl */
:root {
  /* Color - base */
  --form-control-base-border-color: var(--color-neutral-500);
  --form-control-hover-border-color: var(--color-neutral-600);
  --form-control-focused-border-color: var(--color-primary-500);
  --form-control-filled-border-color: var(--color-neutral-500);
  --form-control-label-color: var(--color-neutral-700);
  --form-control-hint-color: var(--color-neutral-700);
  --form-control-icon-color: var(--color-neutral-700);

  /* Color - error */
  --form-control-error-color: var(--color-error-700);
  --form-control-error-label-color: var(--color-error-700);

  /* Color - disabled */
  --form-control-disabled-bg-color: var(--color-neutral-300);
  --form-control-disabled-color: var(--color-neutral-300);

  /* Sizes */
  --form-control-min-height: 48px;
  --from-control-legend-height: 11px;
  --form-control-input-padding: 0;
  --form-control-hint-fint-size: 12px;
}
/* Col */
:root {
  --grid-col-width: calc(100% / 12);
  margin: 0 auto;
}
/* SvgIcon */
:root {
  /* primary */
  --icon-primary-color: var(--color-primary-500);

  /* secondary */
  --icon-secondary-color: var(--color-secondary-500);

  /* tertiary */
  --icon-tertiary-color: var(--color-tertiary-500);
}
/* Icons */
:root {
  /* primary */
  --icon-primary-color: var(--color-primary-500);

  /* secondary */
  --icon-secondary-color: var(--color-secondary-500);

  /* tertiary */
  --icon-tertiary-color: var(--color-tertiary-500);
}
/* Input */
:root {
  /* Color */
  --input-color: var(--color-neutral-900);
  --input-caret-color: var(--color-neutral-900);
  --input-placeholder-color: var(--color-neutral-900);
  --input-disabled-color: var(--color-neutral-300);
}
/* Logo */
:root {
  /* primary */
  --fill-primary-color: var(--color-championship-white);
  --fill-primary-undermark-color: var(--color-heycar-mint-500);

  /* secondary */
  --fill-secondary-color: var(--color-mica-blue);
  --fill-secondary-undermark-color: var(--color-heycar-mint-500);

  /* tertiary */
  --fill-tertiary-color: var(--color-mica-blue);
  --fill-tertiary-undermark-color: var(--color-championship-white);
}
/* Header */
:root {
  /* background color */
  --header-nav-background-color: var(--color-primary-700);
  /* font color */
  --header-nav-item-color: var(--color-championship-white);
  --header-nav-item-hover-bg: var(--color-primary-800);
  --header-nav-item-active-bg: var(--color-primary-900);
  --header-nav-counter-bg: var(--color-secondary-500);
  --header-nav-fav-icon-bg: var(--color-secondary-100);
}
/* Pill */
:root {
  /* background color */
  --pill-background-color: var(--color-tarmac-grey-50);

  /* text color */
  --pill-text-color: var(--color-tarmac-grey-700);
}
/* Slider */
:root {
  --slider-thumb-size: 22px;
  --slider-halo-size: 40px;
  --slider-focus-visible: calc(var(--slider-thumb-size) * 1.1818181818181819);
  --slider-thumb-left-offset: -2px;
}
/* SliderWithHistogram */
:root {
  --histogram-height: 64px;
  --slider-height: 22px;
}
/* Switch */
:root {
  --switch-checked-color: var(--color-neutral-700);
  --switch-unchecked-color: var(--color-neutral-500);
  --switch-unchecked-hover-color: var(--color-neutral-700);
  --switch-unchecked-pressed-color: var(--color-neutral-700);
  --switch-disabled-color: var(--color-neutral-300);
  --switch-thumb-color: var(--color-championship-white);
  --switch-thumb-disabled-color: var(--color-neutral-200);
}
/* Textarea */
:root {
  /* Color */
  --textarea-color: var(--color-neutral-700);
  --textarea-caret-color: var(--color-neutral-700);
  --textarea-placeholder-color: var(--color-neutral-700);
  --textarea-disabled-color: var(--color-neutral-300);

  /* Sizes */
  --form-textarea-min-height: 80px;
}
/* Default theme (light) */
:root {
  /*
    Colors
  */
  --color-primary-50: var(--color-mica-blue-50);
  --color-primary-100: var(--color-mica-blue-100);
  --color-primary-200: var(--color-mica-blue-200);
  --color-primary-300: var(--color-mica-blue-300);
  --color-primary-400: var(--color-mica-blue-400);
  --color-primary-500: var(--color-mica-blue-500);
  --color-primary-600: var(--color-mica-blue-600);
  --color-primary-700: var(--color-mica-blue-700);
  --color-primary-800: var(--color-mica-blue-800);
  --color-primary-900: var(--color-mica-blue-900);
  --color-secondary-50: var(--color-heycar-mint-50);
  --color-secondary-100: var(--color-heycar-mint-100);
  --color-secondary-200: var(--color-heycar-mint-200);
  --color-secondary-300: var(--color-heycar-mint-300);
  --color-secondary-400: var(--color-heycar-mint-400);
  --color-secondary-500: var(--color-heycar-mint-500);
  --color-secondary-600: var(--color-heycar-mint-600);
  --color-secondary-700: var(--color-heycar-mint-700);
  --color-secondary-800: var(--color-heycar-mint-800);
  --color-secondary-900: var(--color-heycar-mint-900);
  --color-tertiary-50: var(--color-sunbeam-blue-50);
  --color-tertiary-100: var(--color-sunbeam-blue-100);
  --color-tertiary-200: var(--color-sunbeam-blue-200);
  --color-tertiary-300: var(--color-sunbeam-blue-300);
  --color-tertiary-400: var(--color-sunbeam-blue-400);
  --color-tertiary-500: var(--color-sunbeam-blue-500);
  --color-tertiary-600: var(--color-sunbeam-blue-600);
  --color-tertiary-700: var(--color-sunbeam-blue-700);
  --color-tertiary-800: var(--color-sunbeam-blue-800);
  --color-tertiary-900: var(--color-sunbeam-blue-900);
  --color-warning-50: var(--color-mustang-yellow-50);
  --color-warning-100: var(--color-mustang-yellow-100);
  --color-warning-200: var(--color-mustang-yellow-200);
  --color-warning-300: var(--color-mustang-yellow-300);
  --color-warning-400: var(--color-mustang-yellow-400);
  --color-warning-500: var(--color-mustang-yellow-500);
  --color-warning-600: var(--color-mustang-yellow-600);
  --color-warning-700: var(--color-mustang-yellow-700);
  --color-warning-800: var(--color-mustang-yellow-800);
  --color-warning-900: var(--color-mustang-yellow-900);
  --color-highlight-50: var(--color-old-ferrari-red-50);
  --color-highlight-100: var(--color-old-ferrari-red-100);
  --color-highlight-200: var(--color-old-ferrari-red-200);
  --color-highlight-300: var(--color-old-ferrari-red-300);
  --color-highlight-400: var(--color-old-ferrari-red-400);
  --color-highlight-500: var(--color-old-ferrari-red-500);
  --color-highlight-600: var(--color-old-ferrari-red-600);
  --color-highlight-700: var(--color-old-ferrari-red-700);
  --color-highlight-800: var(--color-old-ferrari-red-800);
  --color-highlight-900: var(--color-old-ferrari-red-900);
  --color-success-50: var(--color-green-50);
  --color-success-100: var(--color-green-100);
  --color-success-200: var(--color-green-200);
  --color-success-300: var(--color-green-300);
  --color-success-400: var(--color-green-400);
  --color-success-500: var(--color-green-500);
  --color-success-600: var(--color-green-600);
  --color-success-700: var(--color-green-700);
  --color-success-800: var(--color-green-800);
  --color-success-900: var(--color-green-900);
  --color-error-50: var(--color-red-50);
  --color-error-100: var(--color-red-100);
  --color-error-200: var(--color-red-200);
  --color-error-300: var(--color-red-300);
  --color-error-400: var(--color-red-400);
  --color-error-500: var(--color-red-500);
  --color-error-600: var(--color-red-600);
  --color-error-700: var(--color-red-700);
  --color-error-800: var(--color-red-800);
  --color-error-900: var(--color-red-900);
  --color-neutral-50: var(--color-tarmac-grey-50);
  --color-neutral-100: var(--color-tarmac-grey-100);
  --color-neutral-200: var(--color-tarmac-grey-200);
  --color-neutral-300: var(--color-tarmac-grey-300);
  --color-neutral-400: var(--color-tarmac-grey-400);
  --color-neutral-500: var(--color-tarmac-grey-500);
  --color-neutral-600: var(--color-tarmac-grey-600);
  --color-neutral-700: var(--color-tarmac-grey-700);
  --color-neutral-800: var(--color-tarmac-grey-800);
  --color-neutral-900: var(--color-tarmac-grey-900);

  /*
    Sizes
  */
  --size-small-height: var(--spacing-8);
  --size-middle-height: var(--spacing-10);
  --size-large-height: var(--spacing-12);

  /*
    Border radius
  */

  --border-radius: 0;
}
html {
  font-size: 16px;
}
body {
  color: var(--color-neutral-700);
}

.LoadingBackground_loadingBackgroundWrapper__zEc91 {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
}

.LoadingBackground_loadingBackground__UZGAi {
  flex: 0 0 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: LoadingBackground_placeHolderShimmer__U0PfD;
  animation-timing-function: linear;
  background-color: var(--color-neutral-50);
  background: linear-gradient(
    to right,
    var(--color-neutral-100) 8%,
    var(--color-neutral-300) 18%,
    var(--color-neutral-100) 33%
  );
  background-size: 800px 104px;

  opacity: 1;
}

.LoadingBackground_loadingBackground__UZGAi[data-is-active='true'] {
    opacity: 1;
  }

@keyframes LoadingBackground_placeHolderShimmer__U0PfD {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.Header_headerContainer__U17nV {
  margin-bottom: 20px;
}
.Header_headerContainer__U17nV h3,
  .Header_headerContainer__U17nV p {
    margin: 0 0 9px;
    position: relative;
  }
.Header_header__gBomM h4,
  .Header_header__gBomM p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }
.Header_link__QnXuR {
  color: var(--neutral-700-pressed-default-text, #303030);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
}
.Header_products__ysDn7 {
  margin-top: var(--spacing-3);
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.Header_product__WnZSC {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}
.Header_product__WnZSC strong {
    font-weight: 500;
  }
.Header_findOut__7p3V_ {
  text-decoration-line: underline;
  cursor: pointer;
}

.Calculator_calculatorWrapper__P6kBZ {
  position: relative;
  margin: 0 0 var(--spacing-10) 0;
  border: 1px solid var(--neutral-300-disabled, #b5b5b5);

  --color-mica-blue-500: #164ca3;

  --color-primary-50: var(--color-mica-blue-50);
  --color-primary-100: var(--color-mica-blue-100);
  --color-primary-200: var(--color-mica-blue-200);
  --color-primary-300: var(--color-mica-blue-300);
  --color-primary-400: var(--color-mica-blue-400);
  --color-primary-500: var(--color-mica-blue-500);
  --color-primary-600: var(--color-mica-blue-600);
  --color-primary-700: var(--color-mica-blue-700);
  --color-primary-800: var(--color-mica-blue-800);
  --color-primary-900: var(--color-mica-blue-900);

  --color-secondary-50: var(--color-heycar-mint-50);
  --color-secondary-100: var(--color-heycar-mint-100);
  --color-secondary-200: var(--color-heycar-mint-200);
  --color-secondary-300: var(--color-heycar-mint-300);
  --color-secondary-400: var(--color-heycar-mint-400);
  --color-secondary-500: var(--color-heycar-mint-500);
  --color-secondary-600: var(--color-heycar-mint-600);
  --color-secondary-700: var(--color-heycar-mint-700);
  --color-secondary-800: var(--color-heycar-mint-800);
  --color-secondary-900: var(--color-heycar-mint-900);

  --button-primary-base-bg-color: var(--color-primary-500);
  --button-primary-hover-bg-color: var(--color-primary-600);
  --button-primary-active-bg-color: var(--color-primary-700);
}

@media (min-width: 1024px) {

.Calculator_calculatorWrapper__P6kBZ {
    margin: var(--spacing-6) 0 var(--spacing-12) 0;
}
  }

.Calculator_calculatorWrapper__P6kBZ > *:not(:last-child) {
    margin: 0 0 var(--spacing-10) 0;
  }

@media (min-width: 1024px) {

.Calculator_calculatorWrapper__P6kBZ > *:not(:last-child) {
      margin: var(--spacing-6) 0 var(--spacing-12) 0;
  }
    }

.Calculator_link__LYQS1 > span {
    color: var(--neutral-700-pressed-default-text, #303030);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
  }

.Calculator_mainWrapper__WPQXw {
  position: relative;
  z-index: 1;
  max-width: 100%;
  padding: var(--spacing-10) var(--spacing-5);
  border-top: 10px solid var(--primary-50-surface, #f1f4fa);
}

@media (min-width: 768px) {

.Calculator_mainWrapper__WPQXw {
    padding: var(--spacing-10) var(--spacing-6);
}
  }

@media (min-width: 1024px) {

.Calculator_mainWrapper__WPQXw {
    padding: var(--spacing-10) var(--spacing-8);
}
  }

@media (min-width: 1440px) {

.Calculator_mainWrapper__WPQXw {
    padding: var(--spacing-10);
}
  }

.Calculator_errorLabel__006t1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 var(--spacing-4) 0 var(--spacing-4);
  line-height: 26px;
}

@media (min-width: 768px) {
    .Calculator_errorLabel__006t1 p {
      line-height: 26px;
    }
  }

.Calculator_mainOverlay__9PiXG {
  position: absolute;
  top: -9px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 103%;
  background: linear-gradient(to top, #fff1ef 50%, rgba(255, 255, 255, 0.8) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

@media (max-width: 767px) {

.Calculator_mainOverlay__9PiXG {
    top: -12px;
    height: 102%;
}
  }

@media (min-width: 768px) {

.Calculator_mainOverlay__9PiXG {
    background: linear-gradient(to left, #fff1ef 50%, rgba(255, 255, 255, 0.8) 100%);
}
  }

.Calculator_mainOverlay__9PiXG.Calculator_visible__H9gvX {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.Calculator_formContainer__ebBX2 {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: var(--spacing-8);
}

@media (min-width: 768px) {

.Calculator_formContainer__ebBX2 {
    flex-direction: row;
    gap: var(--spacing-6);
}
  }

@media (min-width: 1024px) {

.Calculator_formContainer__ebBX2 {
    gap: var(--spacing-8);
}
  }

@media (min-width: 1280px) {

.Calculator_formContainer__ebBX2 {
    gap: var(--spacing-10);
}
  }

.Calculator_content__UlnM6 {
  flex: 1 1 200px;
}

.Calculator_content__UlnM6 > div {
    margin-bottom: 0;
  }

.Calculator_divider__aMKoK {
  border-top: 1px solid var(--color-tarmac-grey-300);
}

@media (min-width: 768px) {

.Calculator_divider__aMKoK {
    border-top: none;
    border-left: 1px solid var(--color-tarmac-grey-300);
}
  }

.Calculator_summaryContainer__GbhbB {
  flex: 1 1 200px;
}

.Calculator_summaryContainer__GbhbB h5,
  .Calculator_summaryContainer__GbhbB p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }

.Calculator_summaryContainer__GbhbB > *,
  .Calculator_summaryContainer__GbhbB > p {
    margin-bottom: var(--spacing-5);
  }

@media (min-width: 768px) {

.Calculator_summaryContainer__GbhbB {
    max-width: calc(50% - 18px);
}
  }

.Calculator_universalSummaryContainer__BzToZ {
  margin-top: var(--spacing-5);
}

.Calculator_universalSummaryContainer__BzToZ h5,
  .Calculator_universalSummaryContainer__BzToZ p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }

.Calculator_universalSummaryContainer__BzToZ > *,
  .Calculator_universalSummaryContainer__BzToZ > p {
    margin-bottom: var(--spacing-5);
  }

.Calculator_summaryFakeContainer__ifnVp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {

.Calculator_summaryFakeContainer__ifnVp {
    flex-direction: column;
    align-items: flex-start;
}
  }

.Calculator_ctasContainer__QKG4h {
  width: auto;
  text-align: center;
}

.Calculator_ctasContainer__QKG4h > :first-child {
    margin-bottom: var(--spacing-3);
  }

@media (min-width: 768px) {

.Calculator_ctasContainer__QKG4h > :first-child {
      margin-bottom: 0;
  }
    }

.Calculator_universalCtasContainer__znLIx {
  text-align: right;
}

.Calculator_universalCtasContainer__znLIx > :first-child {
    margin-bottom: var(--spacing-3);
  }

@media (min-width: 768px) {

.Calculator_universalCtasContainer__znLIx > :first-child {
      margin-bottom: 0;
  }
    }

.Calculator_universalButton__7EMZv {
  width: 280px;
}

@media (max-width: 767px) {

.Calculator_universalButton__7EMZv {
    width: 100%;
}
  }

.Calculator_summaryTitle__brbPX [class*='typography'] {
    position: relative;
  }

.Calculator_fakeSummary__zjeoh {
  height: 380px;
  position: relative;
}

.Calculator_universalFakeSummary__ebid5 {
  height: 180px;
  position: relative;
}

.Calculator_fakeCheckoutSummary__YaGYM {
  height: 180px;
  display: flex;
  flex-direction: row;
}

.Calculator_fakeButton__eaDCP {
  height: 43px;
  width: 100%;
  position: relative;
}

.Calculator_fakeButtons__vemVE {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  margin-bottom: var(--spacing-8) !important;
  max-width: 100%;
  position: relative;
}

@media (min-width: 1024px) {

.Calculator_modalContainer__lHSIN {
    height: 635px;
    width: 800px;
}
  }

@media (min-width: 1024px) {

.Calculator_emailModalContainer__hPpJ0 {
    width: 800px;
    height: 330px;
}
  }

.Calculator_modalBody__DWz03 {
  overflow: auto;
}

.Calculator_error__MW3g_ {
  flex: 1 1 200px;
}

.Calculator_error__MW3g_ div {
    background-color: var(--color-red-50);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 auto var(--spacing-5);
    padding: var(--spacing-5);
  }

.Calculator_error__MW3g_ svg {
    margin: 0 auto;
  }

.Calculator_error__MW3g_ h5,
  .Calculator_error__MW3g_ p,
  .Calculator_error__MW3g_ svg {
    margin-bottom: var(--spacing-5);
    margin-top: 0;
  }

.Calculator_error__MW3g_ > *,
  .Calculator_error__MW3g_ > p {
    margin-bottom: var(--spacing-5);
  }

@media (min-width: 768px) {

.Calculator_error__MW3g_ {
    max-width: calc(100% - 18px);
}
  }

.Calculator_financePreApprovalWrapper__xjxUo {
  text-align: left;
}

.Calculator_financePreApprovalTitle__eAZq2 {
  margin: var(--spacing-4) 0 var(--spacing-2) 0;
}

.Calculator_financePreApprovalTitle__eAZq2 > p {
    font-size: 16px;
  }

.Calculator_financePreApprovalErrorIcon__WhP8j {
  text-align: center;
}

.Calculator_financePreApprovalCta__b9zNJ {
  margin-top: var(--spacing-5);
}

.Calculator_lenderNote__vuTmg {
  display: flex;
  text-align: start;
  margin-bottom: var(--spacing-3) !important;
}

.Calculator_supportOptIn__z4pBk label {
    margin: var(--spacing-5) 0;
    display: flex;
    gap: var(--spacing-3);
    align-items: start;
    user-select: none;
  }

.Calculator_supportOptIn__z4pBk p {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-6);
    font-weight: 500;
  }

.Calculator_headerContainer__6QU1M {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: var(--spacing-6);
  margin-bottom: -18px;
  font-weight: 500;
}

.Calculator_headerContainer__6QU1M p,
  .Calculator_headerContainer__6QU1M div {
    margin-top: var(--spacing-2);
    font-weight: 500;
  }

.Calculator_headerContainer__6QU1M div {
    margin-top: var(--spacing-6);
  }

@media (max-width: 767px) {

.Calculator_headerContainer__6QU1M {
    flex-direction: column;
    align-items: flex-start;
}

    .Calculator_headerContainer__6QU1M p {
      margin-top: 0 !important;
    }
  }

.Calculator_clickableSubheader__ImU4G {
  cursor: pointer;
  color: var(--color-primary-500);
  text-decoration: underline;
}

@media (max-width: 767px) {

.Calculator_clickableSubheader__ImU4G {
    font-size: 14px;
    margin-top: 0px;
}
  }

@media (min-width: 1024px) {

.Calculator_modalSpecialContainer__5E3wm {
    height: 635px;
    width: 800px;
}
  }

.Calculator_modalSpecialBody__1Aytf {
  overflow: auto;
  padding: var(--spacing-1) 50px;
}

@media (max-width: 767px) {

.Calculator_modalSpecialBody__1Aytf {
    padding-bottom: var(--spacing-5);
}
  }

.Calculator_modalContent__yK3eF {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.Calculator_footerContent__gbm_Z {
  margin-top: var(--spacing-3);
  padding: 2px 20px;
  line-height: 22px;
}

.Calculator_modalContentBody__8UFkh {
  flex: 1 1;
  font-size: 16px;
}

.Calculator_footerContent__gbm_Z {
  background-color: var(--color-primary-50);
  width: 350px;
}

@media (max-width: 767px),(min-width: 768px) {
  .Calculator_modalContent__yK3eF {
    flex-direction: column;
  }

  .Calculator_footerContent__gbm_Z {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .Calculator_modalContent__yK3eF {
    flex-direction: row;
  }

  .Calculator_footerContent__gbm_Z {
    width: 350px;
  }
}

.Calculator_modalContent__yK3eF h3 {
  margin-bottom: var(--spacing-2);
}

.Calculator_modalContent__yK3eF ul {
  list-style-type: disc;
  padding-left: var(--spacing-3);
}

.AdditionalServices_additionalServicesWrapper__DC48R {
  margin-top: var(--spacing-3);
}
.AdditionalServices_additionalServicesWrapper__DC48R > p {
    display: flex;
    align-items: center;
    margin: 0;
  }
.AdditionalServices_additionalServicesWrapper__DC48R > svg {
    cursor: pointer;
    margin-left: auto;
  }
.AdditionalServices_additionalServiceCheckbox__HVFcn label[for='additionalServices'] {
    display: flex;
    align-items: flex-start;
  }
.AdditionalServices_additionalServicesDe__AzyVh {
  margin-top: -46px;
}
.AdditionalServices_infoIconWrapper__5sHhy {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-3);
}
.AdditionalServices_infoIconWrapper__5sHhy > span:first-child {
    font-size: 14px;
    margin-bottom: var(--spacing-3);
  }
.AdditionalServices_infoIconWrapper__5sHhy > span > svg:first-child {
    margin-bottom: var(--spacing-3);
  }
.AdditionalServices_serviceContainer__o0gzF {
  display: flex;
  padding: var(--spacing-1) 0;
}
.AdditionalServices_serviceContainer__o0gzF > div:last-child {
    display: flex;
  }
.AdditionalServices_serviceContainer__o0gzF > span {
    display: flex;
    align-items: center;
  }
.AdditionalServices_servicePriceContainer__Tkfsn {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.AdditionalServices_servicePrice__DqlSy {
  margin: 0;
  margin-right: 8px;
}
.AdditionalServices_monthlyRate__QJvwj {
  margin-right: var(--spacing-1);
  margin-bottom: var(--spacing-3);
}
.AdditionalServices_videoIcon__xrW8k {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  margin-bottom: var(--spacing-2);
  cursor: pointer;
}
.AdditionalServices_infoIcon__VHUCs {
  margin-left: var(--spacing-1);
  margin-bottom: var(--spacing-2);
  cursor: pointer;
}
.AdditionalServices_noInfo__p_53z {
  cursor: default !important;
}
.AdditionalServices_infoIconNoVideo__nUsSy {
  margin-left: var(--spacing-6);
  cursor: pointer;
}
.AdditionalServices_pdflink__xgj0b {
  color: black;
  cursor: pointer;
}
@media (min-width: 1024px) {
.AdditionalServices_modalContainer__zMUsc {
    height: 635px;
    width: 800px;
}
  }
.AdditionalServices_modalBody__m054g {
  overflow: auto;
}
.AdditionalServices_modalBody__m054g a {
    color: inherit;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

.Summary_container__MmkUe {
  padding: 14px;
  background-color: #f1f4fa;
  list-style: none;
}

.Summary_summary__QTtFt {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Summary_summary__QTtFt > li {
    border-bottom: 1px #b5b5b5;
  }

.Summary_summary__QTtFt > li:last-child {
      border-bottom: none;
    }

.Summary_summary__QTtFt .Summary_instalmentsContainer__05nof {
    border-bottom-style: dashed;
    padding: var(--spacing-2) 0;
  }

.Summary_summary__QTtFt .Summary_instalmentsList__IBt4I {
    list-style: none;
    padding: 0;
    margin: 0;
  }

.Summary_summary__QTtFt li.Summary_instalments__Qh562 {
    margin: 0;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    border-bottom: none;
  }

.Summary_summary__QTtFt li.Summary_instalments__Qh562:not(:first-child) {
      margin-top: var(--spacing-2);
      font-weight: normal;
    }

.Summary_summary__QTtFt li.Summary_instalments__Qh562 span:first-child {
      flex-basis: 70%;
    }

.Summary_summary__QTtFt li.Summary_instalments__Qh562 span:last-child {
      text-align: right;
      flex-basis: 70%;
    }

.Summary_summary__QTtFt li > span > .Summary_monthlyRate__Y8MRC {
    min-width: 188px;
    white-space: wrap;
    font-size: 12px;
  }

.Summary_summary__QTtFt li.Summary_additionalServices__zU4OC span:first-child {
      flex-basis: 60%;
    }

.Summary_summary__QTtFt li > ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

.Summary_summary__QTtFt li > ul li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

.Summary_summary__QTtFt li > ul li span:first-child {
        flex-basis: 55%;
      }

.Summary_summary__QTtFt li > ul li span:last-child {
        font-weight: 500;
        text-align: right;
      }

.Summary_totalMonthlyRate__nj2BV {
  font-weight: 700 !important;
}

.Summary_appFeeContent__jMKEP {
  margin-left: 14px;
  white-space: nowrap;
}

.Summary_infoIcon__JMoLp {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 var(--spacing-1) 0 var(--spacing-1);
}

.Summary_divider__qv_bv {
  border-bottom: 1px solid var(--neutral-300, #b5b5b5);
  margin: var(--spacing-4) 0 var(--spacing-4) !important;
}

.Summary_totalAmountLabel__cQN0E {
  font-weight: normal !important;
}

.ProductToggle_financeOptionsType__kk0sg {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--color-tarmac-grey-300);
  margin: 0;
  padding: 0;
  height: 48px;
  position: relative;
}

.ProductToggle_financeOption__Z_cLJ {
  flex: 1 1;
  text-align: center;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease;
  border-right: 1px solid var(--color-tarmac-grey-300);
  margin: 0;
}

@media (max-width: 767px) {

.ProductToggle_financeOption__Z_cLJ {
    height: 48px;
    border-right: none;
    border-bottom: 1px solid var(--color-tarmac-grey-300);
}

    .ProductToggle_financeOption__Z_cLJ:last-child {
      border-bottom: none;
    }
  }

.ProductToggle_financeOption__Z_cLJ:last-child {
    border-right: none;
  }

.ProductToggle_financeOption__Z_cLJ > * {
    cursor: pointer;
  }

.ProductToggle_financeOption__Z_cLJ > input {
    opacity: 0;
    height: 0;
    width: 0;
  }

.ProductToggle_financeOption__Z_cLJ > label {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing-2);
  }

.ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-2w-2p-37-14-2x-32-34-39-38-2j-38-3d-34-2t-1p-13-36-2p-2s-2x-33-13-2l-1m-2r-2w-2t-2r-2z-2t-2s-15]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(does-not-exist) {
    background: var(--color-mica-blue-50);
    border: 2px solid var(--color-mica-blue-500);
    color: var(--color-mica-blue-500);
    margin: -1px;
    z-index: 1;
    height: calc(100% + 2px);
  }

.ProductToggle_financeOption__Z_cLJ:has(input[type='radio']:checked) {
    background: var(--color-mica-blue-50);
    border: 2px solid var(--color-mica-blue-500);
    color: var(--color-mica-blue-500);
    margin: -1px;
    z-index: 1;
    height: calc(100% + 2px);
  }

@media (max-width: 767px) {

.ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-2w-2p-37-14-2x-32-34-39-38-2j-38-3d-34-2t-1p-13-36-2p-2s-2x-33-13-2l-1m-2r-2w-2t-2r-2z-2t-2s-15]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(does-not-exist) {
      height: calc(48px + 2px);
  }

.ProductToggle_financeOption__Z_cLJ:has(input[type='radio']:checked) {
      height: calc(48px + 2px);
  }
    }

.ProductToggle_divider__ca4y6 {
  border-bottom: 1px solid var(--neutral-100, #e6e6e6);
  margin: var(--spacing-6) 0 var(--spacing-6) !important;
}

@media (max-width: 767px) {
  .ProductToggle_financeOptionsType__kk0sg {
    flex-direction: column;
    height: auto;
  }

  .ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-37-2c-3d-34-2t-1m-2w-2p-37-14-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-32-38-2w-19-2r-2w-2x-30-2s-14-1e-15-1m-30-2p-37-38-19-2r-2w-2x-30-2s-15]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ) {
    min-height: 96px;
  }

  .ProductToggle_financeOptionsType__kk0sg:has(.ProductToggle_financeOption__Z_cLJ:nth-child(2):last-child) {
    min-height: 96px;
  }

  .ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-37-2c-3d-34-2t-1m-2w-2p-37-14-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-32-38-2w-19-2r-2w-2x-30-2s-14-1e-15-1m-30-2p-37-38-19-2r-2w-2x-30-2s-15-w-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ) {
    height: 48px;
    min-height: 48px;
    flex: 0 0 48px;
  }

  .ProductToggle_financeOptionsType__kk0sg:has(.ProductToggle_financeOption__Z_cLJ:nth-child(2):last-child) .ProductToggle_financeOption__Z_cLJ {
    height: 48px;
    min-height: 48px;
    flex: 0 0 48px;
  }

  .ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-37-2c-3d-34-2t-1m-2w-2p-37-14-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-32-38-2w-19-2r-2w-2x-30-2s-14-1f-15-15]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ) {
    min-height: 144px;
  }

  .ProductToggle_financeOptionsType__kk0sg:has(.ProductToggle_financeOption__Z_cLJ:nth-child(3)) {
    min-height: 144px;
  }

  .ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-37-2c-3d-34-2t-1m-2w-2p-37-14-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-32-38-2w-19-2r-2w-2x-30-2s-14-1f-15-15-w-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ) {
    height: 48px;
    min-height: 48px;
    flex: 1 1;
  }

  .ProductToggle_financeOptionsType__kk0sg:has(.ProductToggle_financeOption__Z_cLJ:nth-child(3)) .ProductToggle_financeOption__Z_cLJ {
    height: 48px;
    min-height: 48px;
    flex: 1 1;
  }
}

.InstamotionFinance_title___fy2U {
  margin: 0 0 var(--spacing-6);
}

.InstamotionFinance_toggleWrapper__QM3NE {
  border: 1px solid var(--color-tarmac-grey-300);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-6);
}

.InstamotionFinance_toggleWrapper__QM3NE button {
    border: 2px solid white;
    cursor: pointer;
    flex: 1 1 270px;
    font-weight: 500;
    height: 48px;
    text-align: center;
    transition: all 0.2s ease;
  }

.InstamotionFinance_toggleWrapper__QM3NE button.InstamotionFinance_selected__Y17pi {
      background: var(--color-mica-blue-50);
      border: 2px solid var(--color-mica-blue-500);
      color: var(--color-mica-blue-500);
    }

.InstamotionFinance_tab__ugxcm {
  background: #f1f4fa;
}

.InstamotionFinance_tab__ugxcm ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
  }

.InstamotionFinance_tab__ugxcm li {
    display: flex;
    padding: 0 var(--spacing-3) var(--spacing-3);
    width: 100%;
    font-size: 14px;
  }

@media (min-width: 768px) {

.InstamotionFinance_tab__ugxcm li {
      flex: 0 0 50%;
  }
    }

.InstamotionFinance_tab__ugxcm span {
    margin-left: auto;
  }

.InstamotionFinance_tab__ugxcm svg {
    margin-right: 10px;
  }

.InstamotionFinance_tabHeader__aFsUB {
  border-bottom: 1px dashed #b5b5b5;
  padding: var(--spacing-3) 0px;
  margin: 0 var(--spacing-3);
}

.InstamotionFinance_tabHeader__aFsUB h5 {
    margin: var(--spacing-2) 0 0;
  }

.InstamotionFinance_tabHeader__aFsUB p {
    margin: var(--spacing-2) 0 0;
  }

.PdpAds_pdpTopAds___dh9S {
  position: sticky;
  top: var(--pdpTopAdsOffset, 64px);
  left: 0;
  width: 100vw;
  margin: 0 -20px;
  z-index: 99;
}

@media (min-width: 768px) {

.PdpAds_pdpTopAds___dh9S {
    top: 64px;
}
  }

@media (min-width: 768px) {

.PdpAds_pdpTopAds___dh9S[data-has-default-header='true'] {
      top: 72px;
  }
    }

[data-service-consumer='native'] .PdpAds_pdpTopAds___dh9S {
    background: white;
    opacity: 0;

    transition: 0.4s;
    transition-delay: 0.01s;
    overflow: hidden;
  }

.PdpAds_js-has-pseudo__YwO9M [csstools-has-2j-2s-2p-38-2p-19-37-2t-36-3a-2x-2r-2t-19-2r-33-32-37-39-31-2t-36-1p-13-32-2p-38-2x-3a-2t-13-2l-w-1a-34-2s-34-2c-33-34-1t-2s-37-1m-2w-2p-37-14-2x-2u-36-2p-31-2t-15]:not(.PdpAds_does-not-exist__5rPjv):not(does-not-exist) {
      opacity: 1;
      height: 66px;
    }

[data-service-consumer='native'] .PdpAds_pdpTopAds___dh9S:has(iframe) {
      opacity: 1;
      height: 66px;
    }

[data-has-footer-sticky='true'] .PdpAds_pdpTopAds___dh9S {
    top: var(--pdpTopAdsOffset, 0);
  }

@media (min-width: 1024px) {

[data-has-footer-sticky='true'] .PdpAds_pdpTopAds___dh9S {
      top: 72px;
  }
    }

.PdpAds_pdpMobileBannerAd__5zuNV {
  display: block;
  height: 66px;
  position: relative;
}

@media (min-width: 776px) {

.PdpAds_pdpMobileBannerAd__5zuNV {
    display: none;
}
  }

.PdpAds_pdpMobileBannerAd__5zuNV > div {
    left: 0;
    right: 0;
    background: white;
    z-index: 50;
    padding: var(--spacing-2) 0;
  }

.PdpAds_pdpMobileBannerAd__5zuNV > div[data-is-viewable='false'] {
    padding: 0;
  }

.PdpAds_js-has-pseudo__YwO9M [csstools-has-1a-34-2s-34-25-33-2q-2x-30-2t-1u-2p-32-32-2t-36-1t-2s-1m-2w-2p-37-14-1q-w-2s-2x-3a-2j-2s-2p-38-2p-19-2x-37-19-3a-2x-2t-3b-2p-2q-30-2t-1p-13-2u-2p-30-37-2t-13-2l-15]:not(.PdpAds_does-not-exist__5rPjv):not(does-not-exist) {
    height: 0;
  }

.PdpAds_pdpMobileBannerAd__5zuNV:has(> div[data-is-viewable='false']) {
    height: 0;
  }

.PdpAds_pdpLeaderboardAd__ywbwo {
  display: none;
  height: 114px;
}

@media (min-width: 776px) {

.PdpAds_pdpLeaderboardAd__ywbwo {
    display: block;
}
  }

@media (min-width: 1024px) {

.PdpAds_pdpLeaderboardAd__ywbwo {
    display: none;
}
  }

.PdpAds_pdpLeaderboardAd__ywbwo > div {
    left: 0;
    right: 0;
    background: white;
    z-index: 50;
    padding: var(--spacing-3) 0;
  }

.PdpAds_pdpBillboardAd__VSzvq {
  width: 100%;
  display: none;
}

@media (min-width: 1024px) {

.PdpAds_pdpBillboardAd__VSzvq {
    display: block;
    width: auto;
    width: initial;
}
  }

@media (min-width: 1280px) {

.PdpAds_pdpBillboardAd__VSzvq {
    display: block;
    width: auto;
    width: initial;
    margin-right: -100px;
    max-width: calc(100vw - 40px - 40px - 300px - 40px);
}
  }

.PdpAds_pdpDmpuAdContainer__eNKVu {
  order: 1;
  padding-bottom: 200px;
}

@media (min-width: 1024px) {

.PdpAds_pdpDmpuAdContainer__eNKVu {
    display: block;
    order: initial;
}
  }

@media (min-width: 1280px) {

.PdpAds_pdpDmpuAdContainer__eNKVu {
    display: block;
}
  }

@media (max-width: 767px) {

.PdpAds_pdpDmpuAd__Q_nBM {

    background-repeat: repeat;
    background-size: 35%;
    margin-top: var(--spacing-10);
}
    .PdpAds_pdpDmpuAd__Q_nBM.PdpAds_pdpDmpuAd__Q_nBM [data-with-margin='true'] {
      margin-bottom: 0;
    }

    .PdpAds_pdpDmpuAd__Q_nBM.PdpAds_pdpDmpuAd__Q_nBM [class^='Ad_wrapper'] {
      margin-top: 0;
    }
  }

@media (min-width: 1024px) {

.PdpAds_pdpDmpuAd__Q_nBM {
    background: none !important;
}

    .PdpAds_pdpDmpuAd__Q_nBM.PdpAds_pdpDmpuAd__Q_nBM [data-unit-id] {
      top: calc(var(--sticky-info-height, 0) + 145px);
    }
  }

.PdpAds_pdpMobileDmpu__piddL {
  display: block;
  margin-top: var(--spacing-6);
  background-repeat: repeat;
  background-size: 35%;
}

.PdpAds_pdpMobileDmpu__piddL.PdpAds_pdpMobileDmpu__piddL [class^='Ad_wrapper'] {
    margin-top: 0;
  }

@media (min-width: 1024px) {

.PdpAds_pdpMobileDmpu__piddL {
    background: none;
    display: none;
}
  }

.PdpAds_pdpDmpuStickyAd__OOBBC {
  display: none;
}

@media (min-width: 1024px) {

.PdpAds_pdpDmpuStickyAd__OOBBC {
    display: block;
    position: sticky;
    top: calc(var(--sticky-info-height, 412px) + 145px);
}
  }

.PdpAds_carFinanceWidget__wb1_d {
  display: none;
}

@media (min-width: 1024px) {

.PdpAds_carFinanceWidget__wb1_d {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--spacing-10);
}
    .PdpAds_carFinanceWidget__wb1_d.PdpAds_carFinanceWidget__wb1_d a {
      top: calc(var(--sticky-info-height, 412px) + 145px);
    }
  }

.FilteredVehicleCarousel_wrapper__KB0Ot {
  width: 100%;
  padding-bottom: var(--spacing-16);
  padding-top: var(--spacing-16);
}

@media (min-width: 1024px) {

.FilteredVehicleCarousel_wrapper__KB0Ot .reviews-scrollbox {
      width: 100%;
      overflow-y: hidden;
  }
    }

.FilteredVehicleCarousel_wrapper__KB0Ot .reviews-nav {
    display: none;
  }

@media (min-width: 1024px) {

.FilteredVehicleCarousel_wrapper__KB0Ot .reviews-nav {
      display: flex;
      padding-top: var(--spacing-4);
      top: auto;
  }
    }

.FilteredVehicleCarousel_wrapper__KB0Ot.FilteredVehicleCarousel_hasBorderBottom__7Br1z {
    border-bottom: 1px solid var(--color-tarmac-grey-300);
  }

.FilteredVehicleCarousel_wrapper__KB0Ot + .FilteredVehicleCarousel_wrapper__KB0Ot {
    padding-top: 0;
  }

/* modern landing page layouts handle the spaces between components, but older news and guides does not  */

[class^='Landing_page_'] > .FilteredVehicleCarousel_wrapper__KB0Ot {
    padding-top: 0;
    padding-bottom: 0;
  }

.FilteredVehicleCarousel_titleWrapper__fP9lk {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

@media (min-width: 768px) {

.FilteredVehicleCarousel_titleWrapper__fP9lk {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--spacing-6);
}
  }

.FilteredVehicleCarousel_titleWrapper__fP9lk h2 {
    margin: 0;
  }

.FilteredVehicleCarousel_cta__MH5UP {
  padding-top: 0;
  width: 100%;
  color: var(--neutral-700);
  justify-content: start;
}

@media (min-width: 768px) {

.FilteredVehicleCarousel_cta__MH5UP {
    justify-self: flex-end;
    width: auto;
    padding-top: var(--spacing-6);
}
  }

.FilteredVehicleCarousel_reel__pfc69 {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  gap: var(--spacing-4);
}

@media (min-width: 1024px) {

.FilteredVehicleCarousel_reel__pfc69 {
    width: 100%;
}
  }

.FilteredVehicleCarousel_reviews__bvkn3 {
  background: var(--color-championship-white);
  min-width: 300px;
}

.FilteredVehicleCarousel_reviews__bvkn3 article {
    height: 100%;
  }

@media (min-width: 768px) {

.FilteredVehicleCarousel_reviews__bvkn3 {
    flex-grow: 1;
}

    .FilteredVehicleCarousel_reviews__bvkn3 > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

.RecentlyViewed_wrapper__a4Zx_ {
  --internalGap: var(--spacing-5);
  --maxPaddingWidth: min(1340px, 100vw - (2 * var(--internalGap)));
  --sidePadding: calc((100vw - var(--maxPaddingWidth)) / 2);
  --currentContainerWidth: calc(100vw - (2 * var(--sidePadding)));
}

@media (min-width: 768px) {

.RecentlyViewed_wrapper__a4Zx_ {
    --internalGap: var(--spacing-6);
}
  }

@media (min-width: 1024px) {

.RecentlyViewed_wrapper__a4Zx_ {
    --internalGap: var(--spacing-8);
}
  }

@media (min-width: 1366px) {

.RecentlyViewed_wrapper__a4Zx_ {
    --internalGap: var(--spacing-8);
    --maxPaddingWidth: min(1373px, 100vw - (2 * var(--internalGap)));
}
  }

@media (min-width: 1440px) {

.RecentlyViewed_wrapper__a4Zx_ {
    --internalGap: var(--spacing-16);
    --maxPaddingWidth: min(1312px, 100vw - (2 * var(--internalGap)));
}
  }

.RecentlyViewed_wrapper__a4Zx_ {
  max-width: 100%;

  display: none;
  transition: 0.4s;
  opacity: 0;
}

.RecentlyViewed_wrapper__a4Zx_[data-with-top-margin='true'] {
    padding-top: var(--spacing-10);
  }

.RecentlyViewed_wrapper__a4Zx_[data-with-bottom-margin='true'] {
    padding-bottom: var(--spacing-10);
  }

.RecentlyViewed_wrapper__a4Zx_[data-with-top-border='true'] {
    border-top: 1px solid var(--color-tarmac-grey-300);
    padding-top: var(--spacing-20);
    margin-top: var(--spacing-10);
  }

@media (max-width: 767px) {

.RecentlyViewed_wrapper__a4Zx_[data-with-top-border='true'] {
      padding-top: var(--spacing-10);
  }
    }

.RecentlyViewed_wrapper__a4Zx_[data-with-bottom-border='true'] {
    border-bottom: 1px solid var(--color-tarmac-grey-300);
    padding-bottom: var(--spacing-20);
    margin-bottom: var(--spacing-10);
  }

@media (max-width: 767px) {

.RecentlyViewed_wrapper__a4Zx_[data-with-bottom-border='true'] {
      padding-bottom: var(--spacing-10);
  }
    }

.RecentlyViewed_js-has-pseudo__uYnIJ [csstools-has-1a-3b-36-2p-34-34-2t-36-1m-2w-2p-37-14-2j-2s-2p-38-2p-19-3a-2t-2w-2x-2r-30-2t-19-38-2x-30-2t-2l-15]:not(.RecentlyViewed_does-not-exist__Ky2uE),
  .RecentlyViewed_js-has-pseudo__uYnIJ [csstools-has-1a-3b-36-2p-34-34-2t-36-1m-2w-2p-37-14-2j-2s-2p-38-2p-19-3a-2t-2w-2x-2r-30-2t-19-38-2x-30-2t-19-37-2z-2t-30-2t-38-33-32-2l-15]:not(.RecentlyViewed_does-not-exist__Ky2uE) {
    display: flex;
    flex-direction: column;
    opacity: 1;
  }

.RecentlyViewed_wrapper__a4Zx_:has([data-vehicle-tile]),
  .RecentlyViewed_wrapper__a4Zx_:has([data-vehicle-tile-skeleton]) {
    display: flex;
    flex-direction: column;
    opacity: 1;
  }

.RecentlyViewed_wrapper__a4Zx_ {

  @starting-style {
    opacity: 0;
  }
}

.RecentlyViewed_title__rpd1k.RecentlyViewed_title__rpd1k {
  font-size: clamp(24px, 4vw, 32px);
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.RecentlyViewed_lastSearch__G_1E7.RecentlyViewed_lastSearch__G_1E7 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  margin-top: var(--spacing-3);
}

.RecentlyViewed_lastSearch__G_1E7.RecentlyViewed_lastSearch__G_1E7 > * {
    color: inherit;
  }

.RecentlyViewed_list__WOeMa {
  grid-gap: var(--spacing-4);
  gap: var(--spacing-4);
  width: max-content;
  margin-top: var(--spacing-5);

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.RecentlyViewed_trackElement__SUrYw {
  display: contents;
}

.RecentlyViewed_item__JqPGO {
  --maxItems: 1;
  text-decoration: none;
  display: flex;

  width: max(
    calc(
      (var(--currentContainerWidth) - ((var(--maxItems) - 1) * var(--spacing-4))) / var(--maxItems)
    ),
    300px
  );
}

@media (min-width: 564px) {

.RecentlyViewed_item__JqPGO {
    --maxItems: 2;
}
  }

@media (min-width: 768px) {

.RecentlyViewed_item__JqPGO {
    --maxItems: 3;
}
  }

@media (min-width: 1024px) {

.RecentlyViewed_item__JqPGO {
    --maxItems: 4;
}
  }

@media (min-width: 1280px) {

.RecentlyViewed_item__JqPGO {
    --maxItems: 4;
}
  }

.RecentlyViewed_item__JqPGO.RecentlyViewed_item__JqPGO.RecentlyViewed_item__JqPGO > * {
    max-width: none;
  }

.RecentlyViewed_item__JqPGO.RecentlyViewed_item__JqPGO article {
    max-width: none;
    width: 100%;
  }

.RecentlyViewed_continueTile__wpsLB {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-neutral-100, #e6e6e6);
  gap: var(--spacing-2);

  color: var(--Text-Default, #303030);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  min-height: 426px;
  text-decoration: none;
  overflow: clip;
}

.RecentlyViewed_continueTile__wpsLB * {
    user-select: none;
    text-decoration: none;
  }

.RecentlyViewed_continueTile__wpsLB:hover .RecentlyViewed_continueTileIcon__ft8Yy {
      transform: translateX(-2px);
    }

.RecentlyViewed_continueTile__wpsLB:active .RecentlyViewed_continueTileIcon__ft8Yy, .RecentlyViewed_continueTile__wpsLB:focus .RecentlyViewed_continueTileIcon__ft8Yy {
      transform: translateX(-5px);
    }

.RecentlyViewed_continueTileIcon__ft8Yy {
  height: 100px;
  width: 100px;
  align-content: center;
  transition: 0.4s;
}

@font-face {
font-family: '__objektiv_0d33b8';
src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/3fcd33a27296ff83-s.p.woff2) format('woff2');
font-display: block;
font-weight: 100;
}

@font-face {
font-family: '__objektiv_0d33b8';
src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/3fcd33a27296ff83-s.p.woff2) format('woff2');
font-display: block;
font-weight: 400;
}

@font-face {
font-family: '__objektiv_0d33b8';
src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/3fcd33a27296ff83-s.p.woff2) format('woff2');
font-display: block;
font-weight: normal;
}

@font-face {
font-family: '__objektiv_0d33b8';
src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/9c496f57d16399a3-s.p.woff2) format('woff2');
font-display: block;
font-weight: 500;
}

@font-face {
font-family: '__objektiv_0d33b8';
src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/eb5c97377ced185c-s.p.woff2) format('woff2');
font-display: block;
font-weight: 600 700;
}

@font-face {
font-family: '__objektiv_0d33b8';
src: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/6e2115458c57af1d-s.p.woff2) format('woff2');
font-display: block;
font-weight: bold;
}@font-face {font-family: '__objektiv_Fallback_0d33b8';src: local("Arial");ascent-override: 103.86%;descent-override: 27.93%;line-gap-override: 0.00%;size-adjust: 109.19%
}.__className_0d33b8 {font-family: '__objektiv_0d33b8', '__objektiv_Fallback_0d33b8', Objektiv, sans-serif
}.__variable_0d33b8 {--font-family-system: '__objektiv_0d33b8', '__objektiv_Fallback_0d33b8', Objektiv, sans-serif
}


  @media (min-width: 1024px) {

.FaqSection_root__RK5pC {
    display: grid;
    grid-template-columns: [left] 1fr [right] 1fr;
    grid-column-gap: var(--spacing-4);
    column-gap: var(--spacing-4);
}
  }

@media (min-width: 1280px) {

.FaqSection_root__RK5pC {
    display: grid;
    grid-template-columns: [left] 0.706fr [right] 1fr;
    grid-column-gap: var(--spacing-4);
    column-gap: var(--spacing-4);
}
  }

@media (min-width: 768px) {

.FaqSection_title__zpe_G.FaqSection_title__zpe_G {
      grid-column: left;
      margin-bottom: var(--spacing-7);
  }
    }

.FaqSection_description__JndH3 {
  grid-column: right;
  margin-bottom: var(--spacing-6);
}

@media (min-width: 1024px) {

.FaqSection_description__JndH3 {
    margin-top: 0;
}
  }

.FaqSection_accordions__jBOLv {
  grid-column: right;
}

.FaqSection_accordionTitle__LVfwl {
  margin: 0;
}

.FaqSection_button__suVPm {
  justify-self: flex-start;
  grid-column: right;
  margin-top: var(--spacing-6);
}

.Scroller_wrapper__uqOjq {
  user-select: none;
  position: relative;
}

.Scroller_fullWidth__IonKc {
  --internalGap: var(--spacing-5);
  --maxPaddingWidth: min(1340px, 100vw - (2 * var(--internalGap)));
  --sidePadding: calc((100vw - var(--maxPaddingWidth)) / 2);

  position: relative;
  left: calc(var(--sidePadding) * -1);
  right: calc(var(--sidePadding) * -1);
  width: 100vw;
}

@media (min-width: 768px) {

.Scroller_fullWidth__IonKc {
    --internalGap: var(--spacing-6);
}
  }

@media (min-width: 1024px) {

.Scroller_fullWidth__IonKc {
    --internalGap: var(--spacing-8);
}
  }

@media (min-width: 1366px) {

.Scroller_fullWidth__IonKc {
    --internalGap: var(--spacing-8);
    --maxPaddingWidth: min(1373px, 100vw - (2 * var(--internalGap)));
}
  }

@media (min-width: 1440px) {

.Scroller_fullWidth__IonKc {
    --internalGap: var(--spacing-16);
    --maxPaddingWidth: min(1312px, 100vw - (2 * var(--internalGap)));
}
  }

.Scroller_nav__50NrP {
  position: absolute;
  right: 0;
  top: -70px;

  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: var(--spacing-6);
}

@media (min-width: 1024px) {

.Scroller_nav__50NrP {
    display: flex;
}
  }

.Scroller_nav__50NrP svg {
    height: var(--spacing-9);
    width: var(--spacing-9);
    padding: 0 10px;
    cursor: pointer;

    opacity: 0.3;
  }

.Scroller_nav__50NrP svg[data-is-active='true'] {
      opacity: 1;
    }

.Scroller_scrollBox__zpI_y {
  width: 100vw;
  scroll-snap-type: x proximity;
  overflow-x: scroll;
  position: relative;
  /* left: calc(-1 * var(--sidePadding)); */
  padding: 0 var(--sidePadding);
  scroll-padding-left: var(--sidePadding);
  scroll-padding-right: var(--sidePadding);
  display: flex;
  flex-direction: row;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Scroller_scrollBox__zpI_y::-webkit-scrollbar {
    display: none;
  }

[data-tenant='rac'] .Scroller_nav__50NrP svg,
    [data-tenant='rac'] .Scroller_nav__50NrP path {
      fill: var(--color-primary-500);
    }

[data-tenant='rac'] .Scroller_nav__50NrP svg {
      height: var(--spacing-10);
      width: var(--spacing-10);
    }

.Scroller_dots__1wUGe {
  display: flex;
  gap: var(--spacing-1);
}

.Scroller_dot__D6g_w {
  background-color: var(--color-primary-200);
  height: var(--spacing-2);
  width: var(--spacing-2);
  border-radius: 50%;
}

.Scroller_dot__D6g_w[data-is-active='true'] {
    background-color: var(--color-primary-500);
  }

.IntroCarousel_loading__fg9e_ {
  background-color: var(--color-neutral-100);
  height: 180px;
  width: 100%;
}

.IntroCarousel_wrapper__AB22u {
  border: 1px solid var(--color-neutral-100);
  padding: var(--spacing-3);
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
    .IntroCarousel_wrapper__AB22u h1 {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: var(--spacing-3);
    }
  }

@media (min-width: 768px) {

.IntroCarousel_wrapper__AB22u {
    padding: 0 var(--spacing-5) var(--spacing-6);
}

    .IntroCarousel_wrapper__AB22u h1 {
      font-size: 28px;
      line-height: 40px;
    }
  }

.IntroCarousel_wrapper__AB22u .intro-scrollbox {
    gap: var(--spacing-2);
    scroll-snap-type: x mandatory;
    align-items: flex-start;
  }

@media (max-width: 767px) {

.IntroCarousel_wrapper__AB22u .intro-scrollbox {
      width: calc(100vw - 70px);
  }
    }

@media (min-width: 768px) {

.IntroCarousel_wrapper__AB22u .intro-scrollbox {
      width: 100%;
  }
    }

.IntroCarousel_wrapper__AB22u .intro-scrollbox-nav {
    gap: 0;
    display: none;
  }

.IntroCarousel_wrapper__AB22u .intro-scrollbox-nav svg {
      height: var(--spacing-11);
      width: var(--spacing-11);
    }

@media (min-width: 1024px) {

.IntroCarousel_wrapper__AB22u .intro-scrollbox-nav {
      display: flex;
      top: auto;
      bottom: -15px;
      right: -10px;
  }
    }

.IntroCarousel_wrapper__AB22u .intro-scrollbox-dots {
    margin-top: 10px;
  }

@media (max-width: 767px) {

.IntroCarousel_wrapper__AB22u .intro-scrollbox-dots {
      margin-top: 0;
      justify-content: center;
  }
    }

.IntroCarousel_wrapper__AB22u[data-is-opened='false'] {
    height: 42px;
  }

@media (min-width: 768px) {

.IntroCarousel_wrapper__AB22u[data-is-opened='false'] {
      height: 80px;
  }
    }

.IntroCarousel_item__JfnME {
  scroll-snap-align: start;
  padding: 0 0 var(--spacing-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  text-decoration: none;
  transition: 0.1s;
  gap: 0;
}

@media (max-width: 767px) {

.IntroCarousel_item__JfnME {
    min-width: calc(100vw - 70px);
    padding: 0;
    width: calc(100vw - 70px);
}
  }

@media (min-width: 768px) {

.IntroCarousel_item__JfnME {
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 0 100%;
}
  }

@media (max-width: 767px) {
    .IntroCarousel_content__1Rk7O p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: var(--spacing-3);
      margin-top: 0;
    }
  }

@media (min-width: 768px) {

.IntroCarousel_content__1Rk7O {
    width: 85%;
}

    .IntroCarousel_content__1Rk7O p {
      margin-top: 0;
    }
  }

.IntroCarousel_toggle__Z1RLw {
  background-color: var(--color-championship-white);
  border-radius: var(--spacing-1);
  cursor: pointer;
  padding: var(--spacing-1) var(--spacing-2);
  position: absolute;
  top: var(--spacing-1);
  right: var(--spacing-1);
}

@media (max-width: 767px) {
    .IntroCarousel_toggle__Z1RLw span {
      display: none;
    }
  }

@media (min-width: 768px) {

.IntroCarousel_toggle__Z1RLw {
    top: var(--spacing-6);
    right: var(--spacing-5);
}

    .IntroCarousel_toggle__Z1RLw span {
      font-size: 12px;
    }

    .IntroCarousel_toggle__Z1RLw div {
      display: none;
    }
  }

.VideoPromo_layout__nFyLY {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--spacing-6) var(--spacing-16);
  flex-wrap: wrap;
}

@media (min-width: 1440px) {

.VideoPromo_layout__nFyLY {
    gap: var(--spacing-5) 80px;
}
  }

.VideoPromo_content__a5gzm {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-5);
}

@media (min-width: 1024px) {

.VideoPromo_content__a5gzm {
    gap: var(--spacing-6);
    width: 448px;
}
  }

@media (min-width: 1440px) {

.VideoPromo_content__a5gzm {
    width: 477px;
}
  }

.VideoPromo_content__a5gzm.VideoPromo_content__a5gzm > * {
      margin: 0;
    }

.VideoPromo_content__a5gzm.VideoPromo_content__a5gzm > * p {
        margin: 0;
        padding: 0;
        line-height: 1.5;
      }

.VideoPromo_title__23_1q {
  line-height: 1.3;
}

@media (min-width: 1024px) {

.VideoPromo_title__23_1q {
    max-width: 350px;
}
  }

@media (max-width: 767px) {

.VideoPromo_title__23_1q {
    font-size: var(--spacing-6);
}
  }

.VideoPromo_cta__FJd7u {
  width: fit-content;
}

.VideoPromo_text___WNR_ {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);
}

.VideoPromo_text___WNR_ > p > strong {
    font-weight: 500;
  }

@media (min-width: 1024px) {

.VideoPromo_text___WNR_ {
    max-width: 455px;
}
  }

.VideoPromo_video__Vfkyl {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(755px * 0.6);

  aspect-ratio: 16/9;
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {

.VideoPromo_video__Vfkyl {
    flex: 1 1 auto;
    width: 448px;
    height: 280px;
    align-self: center;
}
  }

@media (min-width: 1440px) {

.VideoPromo_video__Vfkyl {
    width: 755px;
    height: auto;
    height: initial;
}
  }

.VideoPromo_playIcon__eLJ8k {
  position: absolute;
  z-index: 11;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1000px;
  height: 150px;
  width: 150px;
  padding: var(--spacing-8);
  padding-right: var(--spacing-5);

  cursor: pointer;
  pointer-events: none;
  transition: 0.2s ease-in-out;
  transform: scale(1);
}

.VideoPromo_playIcon__eLJ8k[data-has-started='true'] {
    transition: 0.6s ease-in-out;
    transform: scale(1.3);
    opacity: 0;
    pointer-events: none;
  }

@media (max-width: 767px) {

.VideoPromo_playIcon__eLJ8k {
    height: 80px;
    width: 80px;
    padding: var(--spacing-6);
    padding-right: var(--spacing-4);
}
  }

.VideoPromo_thumbnail__cgMmr {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
  opacity: 1;
  z-index: 10;
  transition: opacity 1.4s ease-in;
  cursor: pointer;
}

.VideoPromo_thumbnail__cgMmr[data-has-started='true'] {
    opacity: 0;
    pointer-events: none;
  }

.VideoPromo_thumbnail__cgMmr:hover + .VideoPromo_playIcon__eLJ8k {
      /* transition: 0.1s linear; */
      transform: scale(1.05);
    }

.EvSpecs_wrapper__5NUQ3 {
  --pdpOverviewPadding: var(--spacing-8);

  interpolate-size: allow-keywords; /* 👈 */
  background-color: var(--color-secondary-700);
  color: white;
  padding: var(--pdpOverviewPadding);
  position: relative;
  overflow: clip;

  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-flow: row;
  grid-gap: var(--spacing-5);
  gap: var(--spacing-5);

  margin-bottom: var(--spacing-5);
  transition: 0.4s ease-out;

  @starting-style {
    opacity: 0;
  }
}

@media (min-width: 1280px) {

.EvSpecs_wrapper__5NUQ3 {
    padding-right: 132px;
}
  }

@media (max-width: 767px) {

.EvSpecs_wrapper__5NUQ3 {
    grid-template-columns: 1fr;
}
  }

.EvSpecs_js-has-pseudo__kMHyH [csstools-has-1a-3b-36-2p-34-34-2t-36-1m-2w-2p-37-14-2j-2s-2p-38-2p-19-32-33-19-2t-3a-19-2s-2p-38-2p-2l-15]:not(.EvSpecs_does-not-exist__B8yw9) {
    display: none;
  }

.EvSpecs_wrapper__5NUQ3:has([data-no-ev-data]) {
    display: none;
  }

.EvSpecs_title__kL9mN {
  grid-column: 1 / -1;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  margin-bottom: var(--spacing-2);
  line-height: 1.3;
}

.EvSpecs_spec__qL0Pj {
  display: grid;
  grid-gap: 2px;
  gap: 2px;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column: span 1;
  align-items: center;
  transition: 0.4s ease-out;
  position: relative;

  @starting-style {
    opacity: 0;
  }
}

@media (max-width: 767px) {
    .EvSpecs_spec__qL0Pj[data-should-hide-mobile='true'] {
      display: none;
    }

    .EvSpecs_js-has-pseudo__kMHyH [csstools-has-1a-3b-36-2p-34-34-2t-36-1m-2w-2p-37-14-1a-37-2w-33-3b-2c-33-2v-2v-30-2t-w-2x-32-34-39-38-1m-2r-2w-2t-2r-2z-2t-2s-15-w-1a-37-34-2t-2r]:not(.EvSpecs_does-not-exist__B8yw9):not(.EvSpecs_does-not-exist__B8yw9):not(.EvSpecs_does-not-exist__B8yw9):not(does-not-exist) {
      display: grid;
    }

    .EvSpecs_wrapper__5NUQ3:has(.EvSpecs_showToggle__NLea6 input:checked) .EvSpecs_spec__qL0Pj {
      display: grid;
    }
  }

.EvSpecs_specIcon__y2k3Z {
  width: 24px;
  height: 24px;
  grid-column: 1;
  grid-row: 1;
}

.EvSpecs_specLabel__VFRSD {
  font-size: 14px;
  grid-column: 2;
  grid-row: 1;
  line-height: 1.4;
}

.EvSpecs_specValue__i7le1 {
  grid-column: 2;
  grid-row: 2;
  font-size: 16px;
  font-weight: 500;
}

.EvSpecs_compareButtonWrapper__UXdt3 {
  grid-column: 1 / -1;
}

.EvSpecs_compareButton__Qgkm1 {
  color: white;
  border-color: white;
}

.EvSpecs_compareButton__Qgkm1:after {
    border-color: white;
  }

@media (max-width: 767px) {

.EvSpecs_compareButton__Qgkm1 {
    width: 100%;
}

    .EvSpecs_compareButton__Qgkm1 button {
      width: 100%;
    }
  }

.EvSpecs_image__p_SSr {
  position: absolute;

  height: 35%;
  width: auto;
  aspect-ratio: 16/9;
  object-fit: contain;
  object-position: right bottom;
  bottom: 20px;
  left: max(380px, 45%);

  transition: 1s ease-out;
}

.EvSpecs_image__p_SSr * {
    fill: white;
  }

.EvSpecs_image__p_SSr {

  @starting-style {
    opacity: 0;
    transform: translateX(10px) translateY(2px);
  }
}

@media (max-width: 767px) {

.EvSpecs_image__p_SSr {
    display: none;
}
  }

.EvSpecs_footnotes__wcV1S {
  grid-column: 1 / -1;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
}

.EvSpecs_showToggle__NLea6 {
  grid-column: 1 / -1;
  place-self: center;
  display: none;
}

@media (max-width: 767px) {

.EvSpecs_showToggle__NLea6 {
    display: block;
}
  }

.EvSpecs_showToggle__NLea6 input {
    display: none;
  }

.EvSpecs_showToggle__NLea6 label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
    cursor: pointer;
    appearance: none;
  }

.EvSpecs_showToggle__NLea6 .EvSpecs_showLess__DzWJd {
    display: none;
  }

.EvSpecs_js-has-pseudo__kMHyH [csstools-has-1a-37-2w-33-3b-2c-33-2v-2v-30-2t-1m-2w-2p-37-14-2x-32-34-39-38-1m-2r-2w-2t-2r-2z-2t-2s-15-w-1a-37-2w-33-3b-25-33-36-2t]:not(.EvSpecs_does-not-exist__B8yw9):not(.EvSpecs_does-not-exist__B8yw9):not(does-not-exist) {
      display: none;
    }

.EvSpecs_showToggle__NLea6:has(input:checked) .EvSpecs_showMore__X_W4y {
      display: none;
    }

.EvSpecs_js-has-pseudo__kMHyH [csstools-has-1a-37-2w-33-3b-2c-33-2v-2v-30-2t-1m-2w-2p-37-14-2x-32-34-39-38-1m-2r-2w-2t-2r-2z-2t-2s-15-w-1a-37-2w-33-3b-24-2t-37-37]:not(.EvSpecs_does-not-exist__B8yw9):not(.EvSpecs_does-not-exist__B8yw9):not(does-not-exist) {
      display: block;
    }

.EvSpecs_showToggle__NLea6:has(input:checked) .EvSpecs_showLess__DzWJd {
      display: block;
    }

.EvSpecs_chargerType__XOrtW svg {
    position: absolute;
    height: 48px;
    width: 48px;
    top: 0;
    left: min(160px, calc(100vw - 48px));
    border: 1px solid white;
    border-radius: 5px;
  }

.EvSpecsModal_wrapper__rwjav {
  transition-behavior: allow-discrete;
  position: fixed;
  inset: 0;

  display: none;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.3);

  transition: 0.3s;
  backdrop-filter: blur(4px) brightness(90%) contrast(110%);
  overscroll-behavior: none;
  overflow: scroll;

  z-index: 9998;

  @starting-style {
    opacity: 0;
    backdrop-filter: blur(0);
  }
}

body[data-is-ev-compare-modal-open='true'] .EvSpecsModal_wrapper__rwjav {
    display: grid;
  }

.EvSpecsModal_wrapper__rwjav [class*='range-slider__thumb'] {
    transition: 0.1s, left 0s;
  }

.EvSpecsModal_modal__l1MZO {
  overscroll-behavior: none;
  position: relative;
  background: white;
  cursor: default;

  box-shadow: 2px 8px 48px 0px #26262633;

  width: min(800px, 100vw);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 1fr;

  transition: 0.3s;
}

@media (max-width: 767px) {

.EvSpecsModal_modal__l1MZO {
    width: 100vw;
    height: 100vh;
}
  }

.EvSpecsModal_modal__l1MZO {

  @starting-style {
    transform: scale(0.95);
  }
}

.EvSpecsModal_header__vMb3O {
  width: 100%;
  padding: 0 var(--spacing-10);

  border-bottom: #f3f3f3 solid 1px;

  font-weight: 600;
  font-size: 20px;

  display: grid;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
}

@media (max-width: 767px) {

.EvSpecsModal_header__vMb3O {
    padding: 0 var(--spacing-5);
}
  }

.EvSpecsModal_header__vMb3O svg {
    cursor: pointer;
  }

.EvSpecsModal_content__XbbX_ {
  place-self: stretch;
  padding: var(--spacing-8) var(--spacing-10);
  grid-gap: var(--spacing-3);
  gap: var(--spacing-3);
  display: grid;
  grid-template-columns: 1fr 1fr 1px 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1px 1fr 1fr 58px;
  align-items: center;
}

@media (max-width: 767px) {

.EvSpecsModal_content__XbbX_ {
    padding: var(--spacing-8) var(--spacing-5);
    width: 100vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto 1px auto auto 1px auto auto;
    place-self: start;
}
  }

.EvSpecsModal_description__V_5OS {
  grid-column: 1 / -1;
  grid-row: 1;
  margin-bottom: var(--spacing-3);
}

.EvSpecsModal_currentVehicle__QJEjd {
  grid-column: 5;
  grid-row: 2 / 7;

  background: var(--color-secondary-50);
  place-self: stretch;
  padding: var(--spacing-3);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  line-height: 1.1;
  margin-bottom: auto;
}

@media (max-width: 767px) {

.EvSpecsModal_currentVehicle__QJEjd {
    grid-column: 2;
    grid-row: 8;
}
  }

.EvSpecsModal_currentVehicle__QJEjd .EvSpecsModal_costValue__CAcTm {
    @starting-style {
      color: var(--color-secondary-700);
    }
  }

.EvSpecsModal_currentVehicleTitle__4AD5c {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

.EvSpecsModal_costLabel__C_nsQ {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: calc(var(--spacing-2) * -1);
}

.EvSpecsModal_costValue__CAcTm {
  font-weight: 600;
  font-size: 24px;
  width: fit-content;

  transition: all 0.4s ease-out, color 1s linear;
  color: var(--color-neutral-700);

  @starting-style {
    /* opacity: 0; */
    /* translate: -5px; */
    color: var(--color-primary-700);
    /* scale: 1.1; */
  }
}

.EvSpecsModal_costValueSmall__boPU3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  width: fit-content;

  transition: 0.6s ease-out;
  transition-delay: 0.1s;

  @starting-style {
    opacity: 0;
    /* translate: -5px; */
    scale: 1.1;
    font-weight: 900;
  }
}

.EvSpecsModal_iceVehicle__U_mxT {
  grid-column: 4;
  grid-row: 2 / 7;
  padding: var(--spacing-3);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  margin-bottom: auto;
}

@media (max-width: 767px) {

.EvSpecsModal_iceVehicle__U_mxT {
    grid-column: 1;
    grid-row: 8;
}
  }

.EvSpecsModal_iceVehicle__U_mxT .EvSpecsModal_costValue__CAcTm {
    @starting-style {
      color: var(--color-primary-500);
    }
  }

.EvSpecsModal_iceVehicleTitle__egKPz {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.EvSpecsModal_horizontalBar__pM3Ur {
  grid-column: 3;
  grid-row: 2 / 7;

  background: var(--color-neutral-300);
  place-self: stretch;
}

@media (max-width: 767px) {

.EvSpecsModal_horizontalBar__pM3Ur {
    grid-column: 1/-1;
    grid-row: 4;
}
  }

.EvSpecsModal_verticalBar__RThhx {
  grid-row: 4;
  grid-column: 1 / 3;

  background: var(--color-neutral-300);
  place-self: stretch;
}

@media (max-width: 767px) {

.EvSpecsModal_verticalBar__RThhx {
    grid-column: 1/-1;
    grid-row: 7;
}
  }

.EvSpecsModal_inputWrapper__TBhGY {
  align-self: center;
  margin: 0;
  width: 100%;
}

.EvSpecsModal_inputWrapper__TBhGY > div {
    margin: 0;
    width: 100%;
  }

.EvSpecsModal_input__msWpb {
  text-align: right;
  width: 100%;
}

.EvSpecsModal_input__msWpb > div {
    margin: 0;
    width: 100%;
  }

.EvSpecsModal_slider__AOclQ {
  align-self: center;
  margin: 0;
  width: auto;
}

.EvSpecsModal_slider__AOclQ input {
    margin: 0;
    width: auto;
  }

.EvSpecsModal_dropdown__v3Gjo {
  grid-column: 1/3;
}

.EvSpecsModal_disclaimer__OumDm {
  grid-row: 7;
  grid-column: 1/-1;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle;

  color: var(--color-neutral-500);
}

@media (max-width: 767px) {

.EvSpecsModal_disclaimer__OumDm {
    grid-row: 9;
}
  }

.Default_wrapper__yTgN6 {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.2s;
}

.Default_wrapper__yTgN6[data-is-open='true'] {
  grid-template-rows: 1fr;
}

.Default_inner__oH56j {
  overflow: hidden;
}

.Default_toast__gXmsn {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: min(840px, calc(100vw - var(--spacing-10)));
  min-width: 300px;
  padding: var(--spacing-4);
  font-family: var(--font-family-system);
  font-size: 14px;
  transition: all 0.3s ease;
  user-select: none;
  cursor: pointer;
}

.Default_toast__gXmsn .Default_icon__d_W8w {
    margin-right: var(--spacing-3);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

.Default_toast__gXmsn .Default_message__L1sIo {
    flex-grow: 1;
  }

.Default_toast__gXmsn[data-variant='primary'] {
    background-color: var(--color-mica-blue-50);
    /* border: 1px solid var(--color-mica-blue-500); */
    color: var(--color-mica-blue-700);
  }

.Default_toast__gXmsn[data-variant='primary'] .Default_icon__d_W8w {
      background-color: var(--color-mica-blue-500);
      color: var(--color-championship-white);
    }

.Default_toast__gXmsn[data-variant='primary']:hover {
      background-color: var(--color-mica-blue-100);
    }

.Default_toast__gXmsn[data-variant='primary']:active {
      background-color: var(--color-mica-blue-200);
    }

.Default_toast__gXmsn[data-variant='secondary'] {
    background-color: var(--color-heycar-mint-50);
    /* border: 1px solid var(--color-heycar-mint-500); */
    color: var(--color-heycar-mint-700);
  }

.Default_toast__gXmsn[data-variant='secondary'] .Default_icon__d_W8w {
      background-color: var(--color-heycar-mint-500);
      color: var(--color-championship-white);
    }

.Default_toast__gXmsn[data-variant='secondary']:hover {
      background-color: var(--color-heycar-mint-100);
    }

.Default_toast__gXmsn[data-variant='secondary']:active {
      background-color: var(--color-heycar-mint-200);
    }

.Default_toast__gXmsn[data-variant='tertiary'] {
    background-color: var(--color-sunbeam-blue-50);
    /* border: 1px solid var(--color-sunbeam-blue-500); */
    color: var(--color-sunbeam-blue-700);
  }

.Default_toast__gXmsn[data-variant='tertiary'] .Default_icon__d_W8w {
      background-color: var(--color-sunbeam-blue-500);
      color: var(--color-championship-white);
    }

.Default_toast__gXmsn[data-variant='tertiary']:hover {
      background-color: var(--color-sunbeam-blue-100);
    }

.Default_toast__gXmsn[data-variant='tertiary']:active {
      background-color: var(--color-sunbeam-blue-200);
    }

.Default_toast__gXmsn[data-variant='success'] {
    background-color: var(--color-green-50);
    /* border: 1px solid var(--color-green-500); */
    color: var(--color-green-700);
  }

.Default_toast__gXmsn[data-variant='success'] .Default_icon__d_W8w {
      background-color: var(--color-green-500);
      color: var(--color-championship-white);
    }

.Default_toast__gXmsn[data-variant='success']:hover {
      background-color: var(--color-green-100);
    }

.Default_toast__gXmsn[data-variant='success']:active {
      background-color: var(--color-green-200);
    }

.Default_toast__gXmsn[data-variant='warning'] {
    background-color: var(--color-mustang-yellow-50);
    /* border: 1px solid var(--color-mustang-yellow-500); */
    color: var(--color-mustang-yellow-700);
  }

.Default_toast__gXmsn[data-variant='warning'] .Default_icon__d_W8w {
      background-color: var(--color-mustang-yellow-500);
      color: var(--color-championship-white);
    }

.Default_toast__gXmsn[data-variant='warning']:hover {
      background-color: var(--color-mustang-yellow-100);
    }

.Default_toast__gXmsn[data-variant='warning']:active {
      background-color: var(--color-mustang-yellow-200);
    }

.Default_toast__gXmsn[data-variant='error'] {
    background-color: var(--color-old-ferrari-red-50);
    /* border: 1px solid var(--color-old-ferrari-red-500); */
    color: var(--color-old-ferrari-red-700);
  }

.Default_toast__gXmsn[data-variant='error'] .Default_icon__d_W8w {
      background-color: var(--color-old-ferrari-red-500);
      color: var(--color-championship-white);
    }

.Default_toast__gXmsn[data-variant='error']:hover {
      background-color: var(--color-old-ferrari-red-100);
    }

.Default_toast__gXmsn[data-variant='error']:active {
      background-color: var(--color-old-ferrari-red-200);
    }

.Default_toast__gXmsn[data-variant='dark'] {
    background-color: var(--color-neutral-700);
    /* border: 1px solid var(--color-old-ferrari-red-500); */
    color: var(--color-championship-white);
  }

.Default_toast__gXmsn[data-variant='dark'] .Default_icon__d_W8w {
      background-color: transparent;
      color: var(--color-championship-white);
    }

.Default_toast__gXmsn[data-variant='dark']:hover {
      background-color: var(--color-neutral-700);
    }

.Default_toast__gXmsn[data-variant='dark']:active {
      background-color: var(--color-neutral-700);
    }

.Default_toast__gXmsn[data-disabled='true'] {
    background-color: var(--color-tarmac-grey-100);
    /* border: 1px solid var(--color-tarmac-grey-300); */
    color: var(--color-tarmac-grey-500);
    pointer-events: none;
  }

.Default_toast__gXmsn[data-disabled='true'] .Default_icon__d_W8w {
      background-color: var(--color-tarmac-grey-300);
      color: var(--color-championship-white);
    }

@media (max-width: 767px) {

.Default_toast__gXmsn {
    max-width: 100%;
}
  }

.ToastManager_toastList__4DANS {
  position: fixed;
  bottom: var(--spacing-10);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  display: flex;
  flex-direction: column-reverse;
  gap: var(--spacing-3);

  width: 100vw;
  align-items: center;
}.ToastManager_toastList__4DANS > div[data-is-inactive='true'] {
      grid-template-rows: 0fr;
      margin-top: calc(var(--spacing-3) * -1);
    }[lang='fr-FR'] .ToastManager_toastList__4DANS {
    bottom: 100px;
  }@media (min-width: 1280px) {[lang='fr-FR'] .ToastManager_toastList__4DANS {
      bottom: 120px;
  }
    }

.CompareCheckbox_wrpr__Vub_E {
  text-align: center;
}

.CompareCheckbox_wrpr__Vub_E > label > div {
    margin-right: var(--spacing-2);
  }

.CompareCheckbox_link__DgpTm {
  color: #fff;
}

.ProsAndCons_wrapper__jTcCi {
  margin: 0 auto;
  max-width: 1040px;
}

@media (min-width: 768px) {

.ProsAndCons_wrapper__jTcCi {
    display: grid;
    grid-template-columns: [left] 1fr [right] 1fr;
    grid-column-gap: var(--spacing-6);
    column-gap: var(--spacing-6);
}

    .ProsAndCons_wrapper__jTcCi h2 {
      width: 100%;
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }

.ProsAndCons_prosConsWrapper__1J3hr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-5) 0;
}

.ProsAndCons_half__pjkMU:first-of-type {
    border-bottom: 1px solid var(--neutral-300-disabled, #b5b5b5);
    padding-bottom: var(--spacing-5);
  }

.ProsAndCons_half__pjkMU ul {
    margin: 0;
    padding-left: var(--spacing-5);
  }

.ProsAndCons_half__pjkMU li {
    margin-bottom: var(--spacing-3);
    font-size: 14px;
  }

.ProsAndCons_half__pjkMU[data-has-icon='true'] ul {
      margin: 0;
      list-style: none;
      padding-left: var(--spacing-8);
    }

.ProsAndCons_half__pjkMU[data-has-icon='true'] li {
      position: relative;
      margin-bottom: var(--spacing-3);
    }

.ProsAndCons_half__pjkMU[data-has-icon='true'] li:before {
        content: '';
        position: absolute;
        height: 16px;
        width: 16px;
        background-image: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/red-minus-3x.6254d92b.png);
        background-size: cover;
        left: -30px;
        top: 2px;
      }

.ProsAndCons_half__pjkMU.ProsAndCons_pros__GtXmF li:before {
      background-image: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/green-plus-3x.5fae48b4.png);
    }

.ProsAndCons_half__pjkMU.ProsAndCons_cons__PusfH li:before {
      background-image: url(https://web.assets.prod.heycar.com/v6.509.0/_next/static/media/red-minus-3x.6254d92b.png);
    }

.ProsAndCons_half__pjkMU h3,
  .ProsAndCons_half__pjkMU h6 {
    margin: 0;
    padding: 0;
  }

@media (min-width: 768px) {
    .ProsAndCons_half__pjkMU:first-of-type {
      border-right: 1px solid var(--neutral-300-disabled, #b5b5b5);
      padding-right: var(--spacing-6);
      padding-bottom: 0;
      border-bottom: none;
    }
  }

.D2CHowItWorks_wrapper__7cnHM {
  margin: var(--spacing-10) 0;
}

.D2CHowItWorks_wrapper__7cnHM h3 {
    font-size: 24px;
  }

.D2CHowItWorks_list__Yvhb0 {
  display: flex;
  flex-direction: column;
  flex: initial;
  gap: var(--spacing-8);
  margin: 0;
  padding: 0;
}

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_item__KCSSz {
    display: flex;
    gap: var(--spacing-3);
    padding: var(--spacing-1) 0;
    align-items: center;
    position: relative;
  }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_item__KCSSz h4 {
      font-size: 16px;
      line-height: 26px;
      margin: 0;
    }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_item__KCSSz p {
      font-size: 14px;
      line-height: 22px;
      margin: 0;
    }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_icon__P536I {
    background-color: var(--color-primary-50);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
  }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_iconWrapper__uOc1X {
    position: relative;
    height: 100%;
    width: 24px;
  }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_line__MCz1X {
    position: relative;
  }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_line__MCz1X:after {
      background-color: var(--color-primary-50);
      content: '';
      left: calc(50% - 1.5px);
      bottom: -100%;
      text-align: center;
      width: 3px;
      position: absolute;
      height: 100%;
      z-index: 0;
    }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_content__lUHnC {
    flex: 1 1;
  }

.VehicleInfoAndDealerInfoWrapper_carFinanceWidgetMobile__cj_mi {
  margin-bottom: var(--spacing-10);
}

.VehicleInfoAndDealerInfoWrapper_pdpMobileDmpu__Bapqz {
  display: block;
  margin-top: var(--spacing-6);
  background-repeat: repeat;
  background-size: 35%;
}

.VehicleInfoAndDealerInfoWrapper_pdpMobileDmpu__Bapqz.VehicleInfoAndDealerInfoWrapper_pdpMobileDmpu__Bapqz [class^='Ad_wrapper'] {
    margin-top: 0;
  }

@media (min-width: 1024px) {

.VehicleInfoAndDealerInfoWrapper_pdpMobileDmpu__Bapqz {
    background: none;
    display: none;
}
  }


  :scope .LeasingVehicles_container__0HFjJ h2 {
    margin-bottom: 0;
  }

.LeasingVehicles_cta__MFOSN a {
    color: var(--color-neutral-700);
  }

.LeasingVehicles_vehicle__PjFlO {
  width: 323px;
}

@media (min-width: 768px) {

.LeasingVehicles_vehicle__PjFlO {
    flex-grow: 1;
}

    .LeasingVehicles_vehicle__PjFlO > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

.LeasingVehicles_vehicles__nKUxW {
  display: grid;
  padding: 0;
  margin: 0;
  margin-top: var(--spacing-8);
  list-style: none;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: var(--spacing-2);
  gap: var(--spacing-2);
}

@media (min-width: 768px) {

.LeasingVehicles_vehicles__nKUxW {
    gap: var(--spacing-3);
}
  }

@media (min-width: 1024px) {

.LeasingVehicles_vehicles__nKUxW {
    gap: var(--spacing-4);
}
  }

@media (min-width: 1280px) {

.LeasingVehicles_vehicles__nKUxW {
    gap: var(--spacing-5);
}
  }

.LeasingVehicles_vehicles__nKUxW a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
  }

.LeasingVehicles_leasingVehicles__oEJJ_ {
  padding-bottom: var(--spacing-8);
}

@media (min-width: 1280px) {

.LeasingVehicles_leasingVehicles__oEJJ_ {
    padding-bottom: 0;
}
  }

.Newsletter_wrapper__CcDFi {
  background: var(--color-primary-50);
  overflow: hidden;
}
.Newsletter_content__w9y4l {
  gap: var(--spacing-6);
  padding: var(--spacing-10) 0;
}
@media (min-width: 768px) {
.Newsletter_content__w9y4l {
    padding: var(--spacing-12) 0;
}
  }
@media (min-width: 768px) and (orientation: landscape) {
.Newsletter_content__w9y4l {
    align-self: center;
}
  }
@media (min-width: 1024px) {
.Newsletter_content__w9y4l {
    gap: var(--spacing-8);
    padding: var(--spacing-16) 0;
}
  }
@media (min-width: 1280px) {
.Newsletter_content__w9y4l {
    padding-top: calc(var(--spacing-10) * 2);
    padding-bottom: var(--spacing-10);
}
  }
.Newsletter_content__w9y4l a {
    text-decoration: none;
    color: var(--color-mica-blue-500);
  }
.Newsletter_heading__qkhde {
  margin: 0;
}
.Newsletter_subHeading___YkZA {
  margin-bottom: var(--spacing-6);
}
.Newsletter_tcs__1oN2K {
  display: inline;
  padding-top: var(--spacing-3);
}
@media (min-width: 768px) {
.Newsletter_tcs__1oN2K {
    padding-top: var(--spacing-6);
}
  }
.Newsletter_imageContainer__9J1mq {
  flex: 2.5 1 auto;
}
@media (min-width: 768px) {
.Newsletter_imageContainer__9J1mq {
    margin-right: -81px;
}
  }
@media (min-width: 1024px) {
.Newsletter_imageContainer__9J1mq {
    margin-right: 0;
}
  }
.Newsletter_imageContainer__9J1mq img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }

.VideoPromoWrapper_dividerSpacing__NCmo5 {
  margin: 0 0 var(--spacing-10);
}@media (min-width: 1024px) {.VideoPromoWrapper_dividerSpacing__NCmo5 {
    margin: var(--spacing-16) 0;
}
  }@media (min-width: 1440px) {.VideoPromoWrapper_dividerSpacing__NCmo5 {
    margin: var(--spacing-20) 0;
}
  }

.FaqSectionWrapper_faqSectionWrapper__VmxT7 {
  padding: var(--spacing-10) 0;
}

.CarouselInfo_wrapper__hdj8r {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-6);
  margin-bottom: var(--spacing-6);
}

@media (min-width: 768px) {

.CarouselInfo_wrapper__hdj8r {
    margin-bottom: 0;
}
  }

.CarouselInfo_infomationContainer__avD9g:not(:last-child) {
  border-right: 1px solid #ccc;
  padding-right: var(--spacing-6);
}

.CarouselInfo_header__mITX5 {
  margin: 0 0 var(--spacing-2) !important;
}

.InstamotionFinanceWrapper_wrapper__LnZkC {
  border: 1px solid var(--neutral-300-disabled, #b5b5b5);
  margin: 0 0 var(--spacing-10) 0;
}

.InstamotionFinanceWrapper_wrapperInner__Wv2D9 {
  padding: var(--spacing-10) var(--spacing-5);
  border-top: 10px solid var(--primary-50-surface, #f1f4fa);
}

@media (min-width: 768px) {

.InstamotionFinanceWrapper_wrapperInner__Wv2D9 {
    padding: var(--spacing-10) var(--spacing-6);
}
  }

@media (min-width: 1024px) {

.InstamotionFinanceWrapper_wrapperInner__Wv2D9 {
    padding: var(--spacing-10) var(--spacing-8);
}
  }

@media (min-width: 1440px) {

.InstamotionFinanceWrapper_wrapperInner__Wv2D9 {
    padding: var(--spacing-10);
}
  }

.InstamotionFinanceWrapper_termsAndConditions__83unW {
  padding: var(--spacing-3);
  color: var(--neutral-700);
}

.InstamotionFinanceWrapper_termsAndConditions__83unW p,
  .InstamotionFinanceWrapper_termsAndConditions__83unW div {
    margin: 0;
  }

@media (min-width: 768px) {

.InstamotionFinanceWrapper_termsAndConditions__83unW p,
  .InstamotionFinanceWrapper_termsAndConditions__83unW div {
      margin-top: 12px;
  }
    }

.InstamotionFinanceWrapper_js-has-pseudo__jZqA8 [csstools-has-1a-38-2t-36-31-37-1t-32-2s-1v-33-32-2s-2x-38-2x-33-32-37-w-34-1m-2w-2p-37-14-1q-w-2q-36-15]:not(does-not-exist):not(does-not-exist), .InstamotionFinanceWrapper_js-has-pseudo__jZqA8 [csstools-has-1a-38-2t-36-31-37-1t-32-2s-1v-33-32-2s-2x-38-2x-33-32-37-w-2s-2x-3a-1m-2w-2p-37-14-1q-w-2q-36-15]:not(does-not-exist):not(does-not-exist) {
      display: none;
    }

.InstamotionFinanceWrapper_termsAndConditions__83unW p:has(> br), .InstamotionFinanceWrapper_termsAndConditions__83unW div:has(> br) {
      display: none;
    }

.InstamotionFinanceWrapper_infoIcon__n0HeU {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 var(--spacing-1) 0 var(--spacing-1);
}

